// ჩათის ეს ელემენტი ეფარება ზედ ქუქიების "პტიჩკას"
.seasonal-float-img {
  pointer-events: none !important;
}

._x_custom-new-drop {
  ._x_custom-new-formitem {
    height: 48px !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    border-color: rgba(43, 39, 49, 0.2) !important;

    .ng-value-container {
      .ng-placeholder {
        color: rgba(43, 39, 49, 0.7) !important;
        font-size: 10px !important;
        font-family: Conv_MarkGEO-Medium !important;
        height: 14px !important;
        left: 20px !important;
        top: 10px !important;
        padding: 0 !important;
      }

      ._x_custom-new-formitem-sec {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        padding: 1px !important;
      }

      .ng-value {
        margin-top: 10px;
        font-size: 14px !important;
      }
    }
  }
}
.form-control {
  display: block;
  width: 100%;
  height: 60px;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: #455767;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f5f4f5;
  border-radius: 1em;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  color: #455767;
  background-color: #fff;
  border-color: #d6d6d6;
  outline: 0;
  box-shadow: none;
}
.drop {
  display: none;
}
.drop.active {
  display: block;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.custom-control-bundle {
  width: 24px;
}
.custom-control-input {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  width: 20px;
  height: 20px;
  opacity: 0;
}
.custom-control-input-bundle {
  position: absolute;
  right: 0;
  z-index: 2;
  width: 24px;
  height: 24px;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: transparent;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: #7a1dff;
  background-color: #7a1dff;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 4px;
}

.custom-control-label:before {
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -1.75rem;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  cursor: pointer;
}
.custom-control-label:before {
  pointer-events: none;
  background-color: transparent;
  border: 1px solid;
  border-color: #aaa9ad;
}
.custom-control-label-bundle:after,
.custom-control-label-bundle:before {
  top: 0 !important;
  left: unset !important;
  right: 0 !important;
  width: 24px !important;
  height: 24px !important;
  background-color: #eae9ea !important;
  border: none !important;
  background-size: 16px auto !important;
  z-index: 0;
}
.bundle-main-label:after,
.bundle-main-label:before {
  top: 0 !important;
  left: unset !important;
  right: 0 !important;
  width: 24px !important;
  height: 24px !important;
  background-color: #eae9ea;
  border: none !important;
  background-size: 16px auto !important;
}
.bundle-main-label-relative {
  position: unset;
}
.bundle-main-product {
  z-index: 0;
  cursor: not-allowed;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #7a1dff;
  background-image: url('~assets/img/svg/checkmark-white.svg');
  background-size: 10px auto;
  border-radius: 5px;
}

.custom-control-label:after {
  background: 50%/50% 50% no-repeat;
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -1.75rem;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  cursor: pointer;
}

.form-control:focus {
  color: #455767;
  background-color: #fff;
  border-color: #d6d6d6;
  outline: 0;
  box-shadow: none;
}
.form-group .action-buttons-wrap {
  top: 0;
  right: 20px;
  transform: translateY(-5px);
}

.form-group {
  .form-control {
    &.filled + label.label--animated,
    &:focus + label.label--animated {
      top: 16px;
      font-size: 12px;
      // color: $rgba-gray-600-60 !important;
    }

    &.filled + label.label--center.label--animated,
    &:focus + label.label--center.label--animated {
      // @include transform-spacing-left(15px, 0, 0, -50%);
      font-size: 12px;
      // color: $rgba-gray-600-60 !important;
    }

    &.placeholder-transparent {
      &::placeholder {
        color: transparent;
        opacity: 0;
      }
    }

    &[type='password'] {
      padding-right: 120px;
    }

    &:disabled,
    &[readonly] {
      // background-color: $input--border-bottom-disabled-bg;
      //opacity:0.5;
    }
  }

  label.label--animated {
    transition: transition;
    padding: 0px;
    // @include transform-spacing-left(15px, 50%, 0, -50%);
  }

  label.label--center {
    transition: transition;
    // @include transform-spacing-left(50%, 50%, -50%, -50%);
  }

  label {
    color: #908b98;
    font-size: 12px;
    left: 0;
    top: 10px;
    transform: translateY(-100%);
    // @include transform-spacing-left(0, 0, 0, -100%);
  }

  .visibility-eye {
    // @include transform-spacing-right(15px, 50%, 0, -50%);

    svg {
      width: 14px;
      height: 11px;
    }
  }

  /*no-border*/
  .form-control.input--border-bottom {
    height: 40px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
    padding-top: 12px;
    color: #2a262f;
    border: 0;
    border-bottom: 1px solid #7a1dff;
    border-radius: 0;

    &:disabled,
    &[readonly] {
      background-color: white;
      border-color: #f5f4f5;
    }
  }

  /*small input*/
  .form-control.input--small {
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;

    &.small--width {
      width: 135px;
    }

    &:disabled,
    &[readonly] {
      background-color: white;
      border-color: #f5f4f5;
    }
  }

  /*large input*/
  .form-control.input--large {
    height: 70px;
    //padding-left: $input-small-x-padding;
    //padding-right: $input-small-x-padding;
  }

  /*custom-radio*/
  /*custom--switcher OWN*/
  .custom--switcher {
    label {
      padding: 0 5px 3px;
      height: 20px;
      color: #b9b9b9;
    }

    input[type='radio']:checked + label {
    }

    &.switcher--rebeccapurple {
      input[type='radio']:checked + label {
        color: white;
        background-color: #7a1dff;
      }
    }
  }

  /*bootstrap*/
  .custom-switch {
    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-color: white;
      }
    }

    /**/
    &.switch--large {
      .custom-control-label {
        &::before {
          left: -60px;
          width: 50px;
          height: 30px;
          top: -4px;
          border-radius: 15px;
          background-color: #f5f4f5;
        }

        &::after {
          top: 2px;
          left: -55px;
          width: 18px;
          height: 18px;
          background-color: #ffffff;
          border-radius: 50%;
          transform: translateX(0);
        }
      }

      .custom-control-input:checked ~ .custom-control-label {
        &::after {
          background-color: white;
          transform: translateX(22px);
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #7a1dff;
        box-shadow: none;
      }
    }
  }

  // Checkboxes
  //
  // Tweak just a few things for checkboxes.
  .custom-checkbox {
    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-color: #7a1dff;
        background-image: url('~assets/img/svg/checkmark-white.svg');
        background-size: 10px auto;
        border-radius: 5px;
      }

      &::before {
        background-color: transparent;
      }
    }
  }

  /*NG Custom Select box*/
  .ng--custom-selectbox {
    /*custom-select-header*/
    .custom-select-header {
      /*ng-select-container*/
      .ng-select-container {
        height: 100%;
        padding: 0 15px 0 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        overflow: initial;

        .ng-value-container {
          overflow: initial;
        }

        .ng-placeholder {
          position: absolute;
          background: #ffffff;
          transition:
            font-size 0.15s ease-in-out,
            top 0.15s ease-in-out;
          padding: 0 5px;
          top: 50%;
          left: 15px;
          font-size: 14px !important;
          // transform: translate(0, -50%);
        }

        &.ng-has-value {
          .ng-placeholder {
            top: 0;
            transform: translate(0, -50%);
            font-size: 10px !important;
          }
        }

        &::after {
          content: '';
          position: relative;
          background-image: url('~assets/img/svg/addow-down.svg');
          background-repeat: no-repeat;
          background-size: 18px 18px;
          width: 20px;
          height: 22px;
          pointer-events: none;
        }
      }
    }

    .ng-dropdown-panel-items {
      border: 1px solid rgba(43, 39, 49, 0.2);
      border-top: 0;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-right: 0;
      top: unset !important;
      .total-padding {
        margin-bottom: 16px;
      }
    }

    &.seller-page-custom-select {
      .custom-select-header {
        .ng-select-container {
          .ng-placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto !important;
            font-size: 16px !important;
            color: #2b2731 !important;
            background-color: unset !important;
          }
          &.ng-has-value {
            .ng-placeholder {
              background-color: transparent !important;
              font-size: 10px !important;
            }
          }
        }
      }
      .ng-dropdown-panel {
        border: 1px solid rgba(43, 39, 49, 0.2);
        padding-right: 12px;
        margin-top: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px !important;
        height: 438px !important;
        .ng-dropdown-panel-items {
          border: 0 !important;
          border-radius: 8px;
          margin-right: 0;
          margin-top: 0;
          height: 418px;
          max-height: 418px !important;
          .total-padding {
            margin-bottom: 16px;
            height: 430px !important;
          }
          .scrollable-content {
            margin-top: 0;
          }
          .ng-option {
            margin-top: 0 !important;
            padding: 2px 12px;
            color: #2b2731 !important;
            font-size: 16px;
            line-height: 24px;
            &:last-child {
              height: 24px !important;
            }
          }

          .ng-option:hover {
            color: #2b2731 !important;
            // font-family: $font-family-medium!important;
          }
          .ng-option-selected {
            font-family: Conv_MarkGEO-Bold !important;
          }

          &.scroll-host {
            overscroll-behavior: contain;
          }
        }
      }
      .ng-select .ng-clear-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        margin-left: 8px;
      }
    }
    @media only screen and (max-width: 768px) {
      &.seller-page-custom-select {
        .ng-dropdown-panel {
          height: 214px !important;
          .ng-dropdown-panel-items {
            height: 190px !important;
            .ng-option:hover {
              font-family: Conv_MarkGEO-Medium !important;
            }
            .ng-dropdown-panel {
              height: 190px !important;
            }
            .total-padding {
              height: 190px !important;
            }
          }
        }
      }
    }
    .dropdown-header-custom {
      border-bottom: 1px solid rgba(43, 39, 49, 0.2);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .ng-option:nth-child(1) {
        margin-top: 8px !important;
      }
    }

    .dynamic-select-header-custom {
      width: calc(100% + 4px);
      transform: translateX(-2px);
      z-index: 8;
    }

    /*ng-dropdown-panel*/
    .ng-dropdown-panel {
      width: 100%;
      border-bottom-left-radius: 0.4em;
      border-bottom-right-radius: 0.4em;
      background-color: white;
      border-top: 0;
      z-index: 2;

      /*ng-option*/
      .ng-option {
        padding: 7px 20px;
        background-color: white;
        color: #6e6e6e;

        &:hover {
          color: #404040;
        }
      }
    }

    /*ng-select--burger*/
    &.ng-select--burger {
      /*custom-select-header*/
      .custom-select-header {
        &.ng-select-opened::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #7a1dff;
        }

        /*ng-select-container*/
        .ng-select-container {
          justify-content: center;
          color: #7a1dff;

          .ng-value-container {
            flex: initial;
          }

          &::before {
            content: '';
            position: relative;
            background-image: url('~assets/img/svg/menu_active.svg');
            background-repeat: no-repeat;
            background-size: 20px 14px;
            width: 20px;
            min-width: 20px;
            height: 14px;
            pointer-events: none;
            margin-right: 15px;
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

._s_ng-select {
  overflow: visible;
}

._s_ng-select::before {
  content: '\e901';
  position: absolute;
  font-family: 'ex-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // z-index: 1;
  right: 10px;
  top: 12px;
  color: #2b273180;
  transition: 0.3s;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  width: 24px;
  min-height: 24px;
  height: 24px;
}

._s_ng-select.ng-touched.ng-dirty {
  border-color: #2a2730;
}

._s_ng-select.ng-touched.ng-dirty.ng-select-opened {
  border-color: #2a273033;
}

._s_ng-select.ng-select-opened {
  border-color: #2a2730;
}

._s_ng-select.ng-select-opened::before {
  transform: rotate(-180deg);
  color: #2b273180;
}

._s_ng-select .ng-dropdown-panel {
  opacity: 1;
  left: 0;
  top: 100%;
  width: calc(100% + 2px);
  margin-left: -1px;
  background-color: white;
  border: 1px solid #2a27301a;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0px 10px 20px #0000001a;
  padding-top: 7px;
  padding-bottom: 4px;
  height: 266px !important;
}

.ng-dropdown-panel-items .scroll-host {
  height: 266px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 255px !important;
}

._s_ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
  @media (max-width: 769px) {
    max-height: 105px;
  }
}

._s_ng-select .ng-dropdown-panel .ng-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 22px;
  margin-top: 14px;
  color: #2b2731b3;
  font-family: Conv_MarkGEO-Medium;
  text-overflow: clip !important;
  margin-left: 8px;

  &:hover {
    color: #7a1dff;
    font-family: Conv_MarkGEO-bold;
  }
}

._s_ng-select .ng-select-container {
  display: flex;
  height: 100%;
}

._s_ng-select .ng-select-container .ng-value-container {
  cursor: pointer;
}

._s_ng-select .ng-select-container .ng-placeholder {
  color: #2b2731b3;
  font-family: Conv_MarkGEO-SemiBold;
  transition: 0.3s;
  top: 10px;
  display: flex;
  position: absolute;
  font-size: 16px;
}

._s_ng-select .ng-select-container .ng-value-container .ng-value-label {
  white-space: nowrap;
  overflow: hidden;
}

._s_ng-select .ng-select-container.ng-has-value .ng-placeholder {
  position: absolute;
  font-size: 12px;
  transition: 0.3s;
  top: 6px;
  height: 15px;
  color: #2b2731b3;
  font-family: Conv_MarkGEO-Medium;
}

.ng-value {
  top: 14px !important;
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 20px;
  font-family: Conv_MarkGEO-Medium;
  color: #2b2731;
  font-size: 16px;
}

.scroll-host {
  overflow: scroll;
  margin-right: 8px;
  height: 109px;
  top: -4px;
}

.scroll-host::-webkit-scrollbar {
  width: 4px;
  height: 109px;
  margin-right: 8px;
}

.scroll-host::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-right: 8px;
  height: 109px;
}

.scroll-host::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2b27311a;
  margin-right: 8px;
  height: 109px;
}

.ng-option:nth-child(1) {
  margin-top: 14px !important;
}

.dynamic-select-header-custom {
  .ng-option {
    font-size: 14px;
    color: #2b2731b3 !important;
    font-family: Conv_MarkGEO-Medium !important;
  }

  .ng-option:hover {
    color: #7a1dff !important;
    font-family: Conv_MarkGEO-Bold !important;
  }

  .ng-option:nth-child(1) {
    margin-top: 8px !important;
  }
}

.ng-option:last-child {
  height: 36px !important;
  padding-bottom: 16px;
}

@media (max-width: 769px) {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 180px !important;
  }

  ._s_ng-select .ng-dropdown-panel {
    height: 190px !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog-centered:before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: '';
}

body.modal-open .modal,
body.modal-open .modal-backdrop {
  display: block;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-backdrop {
  display: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #1f1f1f;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

body.modal-open .modal,
body.modal-open .modal-backdrop {
  display: block;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  outline: 0;
}

@media only screen and (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}

@media only screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media only screen and (min-width: 576px) {
  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
}

// tootlip
.tooltip {
  padding: 6px 8px;
  max-width: 100%;
  color: white;
}

.position-absolute {
  position: absolute !important;
}

.tooltip.tooltip-bottom-right {
  right: 0;
  bottom: 35px;
  border-radius: 4px;
}

.tooltip.error-tooltip.tooltip-bottom-right:after {
  bottom: 3px;
  right: 11px;
  transform: translateY(50%) rotate(-45deg);
  background-color: #f12d2d;
  width: 11px;
  height: 11px;
  border-radius: 2px;
  content: '';
  position: absolute;
}

.tooltip.error-tooltip.tooltip-bottom-left:after {
  bottom: 3px;
  left: 5px;
  transform: translateY(50%) rotate(-45deg);
  background-color: #f12d2d;
  width: 11px;
  height: 11px;
  border-radius: 2px;
  content: '';
  position: absolute;
}
/*edit-form-wrap*/
.edit-form-wrap {
  .empty-value {
    opacity: 0.4;
    left: 0;
    top: 5px;
  }
}

/*success-image*/
.success-image {
  width: 170px;
}

#countdown {
  svg {
    width: 20px;
    height: 20px;
    transform: rotateY(-180deg) rotateZ(-90deg);

    &.loader {
      width: 28px;
      height: 28px;

      .loader-circle {
        animation: countdown 2s linear infinite forwards;
        stroke-dasharray: 56px;
      }
    }

    circle {
      stroke-dasharray: 52px;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-width: 2px;
      stroke: #7a1dff;
      fill: none;
      animation: countdown 130s linear infinite forwards;
    }
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 113px;
  }
}

// one time code
.custom-scroll-bar {
  /* width */
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #f5f4f5;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.category-custom-scroll-bar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(43, 39, 49, 0.3);
    border-radius: 20px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(43, 39, 49, 0.3);
  }
}
.wishlist-path {
  stroke: #2b2731;
  transition: stroke 0.3s ease;
}
.wishlist-icon:hover .wishlist-path {
  stroke: #7a1dff;
}

.zsiq-float.zsiq-flexM {
  opacity: 0;
}

body [data-id='zsalesiq'] {
  z-index: 50 !important;
}

.chat-bottom-10 {
  bottom: 10px !important;
  opacity: 1 !important;
}

.chat-bottom-70 {
  bottom: 70px !important;
  opacity: 1 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.grecaptcha-badge {
  display: none;
}
