// icons
@font-face {
  font-family: 'icomoon';
  src: url('~assets/atomic-assets/fonts/icomoon.eot?v4');
  src:
    url('~assets/atomic-assets/fonts/icomoon.eot?v4#iefix') format('embedded-opentype'),
    url('~assets/atomic-assets/fonts/icomoon.ttf?v4') format('truetype'),
    url('~assets/atomic-assets/fonts/icomoon.woff?v4') format('woff'),
    url('~assets/atomic-assets/fonts/icomoon.svg?v4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// new fonts
@font-face {
  font-family: 'Conv_MarkGEO-Regular';
  src: url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Regular.eot');
  src:
    local('☺'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Regular.woff') format('woff'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Regular.ttf') format('truetype'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Conv_MarkGEO-SemiBold';
  src: url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-SemiBold.eot');
  src:
    local('☺'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-SemiBold.woff') format('woff'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-SemiBold.ttf') format('truetype'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-SemiBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Conv_MarkGEO-Medium';
  src: url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Medium.eot');
  src:
    local('☺'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Medium.woff') format('woff'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Medium.ttf') format('truetype'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Conv_MarkGEO-Bold';
  src: url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Bold.eot');
  src:
    local('☺'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Bold.woff') format('woff'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Bold.ttf') format('truetype'),
    url('~assets/atomic-assets/fonts/new-fonts/MarkGEO-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
// new icons
@font-face {
  font-family: 'ex-icon';
  src: url('~assets/atomic-assets/fonts/new-fonts/ex-icon.eot?f85b836');
  src:
    url('~assets/atomic-assets/fonts/new-fonts/ex-icon.eot?f85b836#iefix') format('embedded-opentype'),
    url('~assets/atomic-assets/fonts/new-fonts/ex-icon.ttf?f85b836') format('truetype'),
    url('~assets/atomic-assets/fonts/new-fonts/ex-icon.woff?f85b836') format('woff'),
    url('~assets/atomic-assets/fonts/new-fonts/ex-icon.svg?f85b836#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
