[class^='_s_icon-'],
[class*=' _s_icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._s_icon-credit-card:before {
  content: '\e955';
}

._s_icon-dot-delivery:before {
  content: '\e956';
}

._s_icon-gancxadebebi:before {
  content: '\e952';
}

._s_icon-logout:before {
  content: '\e953';
}

._s_icon-orders:before {
  content: '\e954';
}

._s_icon-checked:before {
  content: '\e951';
}

._s_icon-hide:before {
  content: '\e94a';
}

._s_icon-renew:before {
  content: '\e94b';
}

._s_icon-address:before {
  content: '\e94c';
}
._s_icon-invate-friend:before {
  content: '\e957';
}

._s_icon-myapplications:before {
  content: '\e94d';
}

._s_icon-myorders:before {
  content: '\e94e';
}

._s_icon-personalinfo:before {
  content: '\e94f';
}

._s_icon-time:before {
  content: '\e950';
}

._s_icon-write:before {
  content: '\e949';
}

._s_icon-appstore:before {
  content: '\e946';
}

._s_icon-playstore:before {
  content: '\e947';
}

._s_icon-upload:before {
  content: '\e945';
}

._s_icon-amex .path1:before {
  content: '\e928';
  color: rgb(43, 39, 49);
  opacity: 0.1;
}

._s_icon-amex .path2:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(43, 39, 49);
  opacity: 0.8;
}

._s_icon-amex .path3:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(43, 39, 49);
  opacity: 0.8;
}

._s_icon-amex .path4:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(43, 39, 49);
  opacity: 0.8;
}

._s_icon-amex .path5:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(43, 39, 49);
  opacity: 0.8;
}

._s_icon-amex .path6:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(43, 39, 49);
  opacity: 0.8;
}

._s_icon-amex .path7:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(43, 39, 49);
  opacity: 0.8;
}

._s_icon-calendar:before {
  content: '\e92f';
}

._s_icon-category:before {
  content: '\e930';
}

._s_icon-down:before {
  content: '\e931';
}

._s_icon-gift:before {
  content: '\e932';
}

._s_icon-img:before {
  content: '\e933';
}

._s_icon-location-map:before {
  content: '\e934';
}

._s_icon-mastercard:before {
  content: '\e935';
}

._s_icon-minus:before {
  content: '\e936';
}

._s_icon-remove:before {
  content: '\e937';
}

._s_icon-up:before {
  content: '\e938';
}

._s_icon-visa:before {
  content: '\e939';
}

._s_icon-wallet:before {
  content: '\e93a';
}

._s_icon-warning:before {
  content: '\e93b';
}

._s_icon-youtube1:before {
  content: '\e93c';
}

._s_icon-nav_art:before {
  content: '\e900';
}

._s_icon-nav_build_remont:before {
  content: '\e901';
}

._s_icon-nav_electronic:before {
  content: '\e902';
}

._s_icon-nav_fashion:before {
  content: '\e903';
}

._s_icon-nav_food:before {
  content: '\e904';
}

._s_icon-nav_for_office:before {
  content: '\e905';
}

._s_icon-nav_for-cars:before {
  content: '\e906';
}

._s_icon-nav_forkids:before {
  content: '\e907';
}

._s_icon-nav_gamingzone:before {
  content: '\e908';
}

._s_icon-nav_health_beauty:before {
  content: '\e909';
}

._s_icon-nav_home_garden:before {
  content: '\e90a';
}

._s_icon-nav_industrial:before {
  content: '\e90b';
}

._s_icon-nav_music:before {
  content: '\e90c';
}

._s_icon-nav_pets:before {
  content: '\e90d';
}

._s_icon-nav_pharmacy:before {
  content: '\e90e';
}

._s_icon-nav_services:before {
  content: '\e90f';
}

._s_icon-nav_smartsystems:before {
  content: '\e910';
}

._s_icon-nav_sport:before {
  content: '\e911';
}

._s_icon-facebook:before {
  content: '\e912';
}

._s_icon-gmail:before {
  content: '\e913';
}

._s_icon-instagram:before {
  content: '\e914';
}

._s_icon-ui_add:before {
  content: '\e915';
}

._s_icon-ui_arrow_left:before {
  content: '\e916';
}

._s_icon-ui_arrow_right:before {
  content: '\e917';
}

._s_icon-ui_arrow2_left:before {
  content: '\e918';
}

._s_icon-ui_arrow2_right:before {
  content: '\e919';
}

._s_icon-ui_cart:before {
  content: '\e91a';
}

._s_icon-ui_close_remove:before {
  content: '\e91b';
}

._s_icon-ui_done:before {
  content: '\e91c';
}

._s_icon-ui_email:before {
  content: '\e91d';
}

._s_icon-ui_eye_view:before {
  content: '\e91e';
}

._s_icon-ui_gel:before {
  content: '\e91f';
}

._s_icon-ui_more:before {
  content: '\e920';
}

._s_icon-ui_password:before {
  content: '\e921';
}

._s_icon-ui_phone:before {
  content: '\e922';
}

._s_icon-ui_search:before {
  content: '\e923';
}

._s_icon-ui_user:before {
  content: '\e924';
}

._s_icon-ui_whishlist_added:before {
  content: '\e925';
}

._s_icon-ui_whishlist:before {
  content: '\e926';
}

._s_icon-youtube:before {
  content: '\e927';
}

._s_icon-cry-smile:before {
  content: '\e93d';
}

._s_icon-delivery-car:before {
  content: '\e93e';
}

._s_icon-dislike:before {
  content: '\e93f';
}

._s_icon-Group-4890:before {
  content: '\e940';
}

._s_icon-info-elipse:before {
  content: '\e941';
}

._s_icon-info-notif:before {
  content: '\e942';
}

._s_icon-like:before {
  content: '\e943';
}

._s_icon-sale-ribon:before {
  content: '\e944';
}

// new icons
[class^='_x_icon-'],
[class*='_x_icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ex-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._x_icon-Favorites-new-outline:before {
  content: '\e94a';
}
._x_icon-Favorites-new-filled:before {
  content: '\e94d';
}
._x_icon-history:before {
  content: '\e94e';
}
._x_icon-sr-moneybag:before {
  content: '\e94b';
}
._x_icon-ph_megaphone-bold:before {
  content: '\e91d';
}
._x_icon-gift-card-2:before {
  content: '\e94c';
}
._x_icon-sr-warning:before {
  content: '\e949';
}
._x_icon-Warranty:before {
  content: '\e948';
}
._x_icon-Compare:before {
  content: '\e947';
}
._x_icon-bestprice:before {
  content: '\e93f';
}
._x_icon-gridview:before {
  content: '\e940';
}
._x_icon-gridview2:before {
  content: '\e941';
}
._x_icon-help:before {
  content: '\e942';
}
._x_icon-listview:before {
  content: '\e943';
}
._x_icon-camera:before {
  content: '\e93c';
}
._x_icon-love_full:before {
  content: '\e93d';
}
._x_icon-return:before {
  content: '\e93e';
}
._x_icon-linkedin:before {
  content: '\e93a';
}
._x_icon-logout:before {
  content: '\e93b';
}
._x_icon-share:before {
  content: '\e931';
}
._x_icon-wallet:before {
  content: '\e900';
}
._x_icon-addow-down:before {
  content: '\e901';
}
._x_icon-apple:before {
  content: '\e902';
}
._x_icon-arrow-left-1:before {
  content: '\e903';
}
._x_icon-arrow-left:before {
  content: '\e904';
}
._x_icon-arrow-right-1:before {
  content: '\e905';
}
._x_icon-arrow-right:before {
  content: '\e906';
}
._x_icon-arrow-up:before {
  content: '\e907';
}
._x_icon-bank-loan:before {
  content: '\e908';
}
._x_icon-burger:before {
  content: '\e909';
}
._x_icon-call:before {
  content: '\e90a';
}
._x_icon-card:before {
  content: '\e90b';
}
._x_icon-cart-bucket:before {
  content: '\e90c';
}
._x_icon-cash:before {
  content: '\e90d';
}
._x_icon-deliveryy:before {
  content: '\e90e';
}
._x_icon-done-check:before {
  content: '\e90f';
}
._x_icon-edit:before {
  content: '\e910';
}
._x_icon-error-close:before {
  content: '\e911';
}
._x_icon-eye:before {
  content: '\e912';
}
._x_icon-fb:before {
  content: '\e914';
}
._x_icon-filters:before {
  content: '\e915';
}
._x_icon-gancxadebebi:before {
  content: '\e916';
}
._x_icon-gel:before {
  content: '\e917';
}
._x_icon-gift:before {
  content: '\e918';
}
._x_icon-gird-view:before {
  content: '\e919';
}
._x_icon-google-store:before {
  content: '\e91a';
}
._x_icon-google:before {
  content: '\e91b';
}
._x_icon-icons-24-info:before {
  content: '\e91c';
}
._x_icon-info:before {
  content: '\e91e';
}
._x_icon-insta:before {
  content: '\e91f';
}
._x_icon-link-copy:before {
  content: '\e920';
}
._x_icon-list-view:before {
  content: '\e921';
}
._x_icon-location:before {
  content: '\e922';
}
._x_icon-love-favorite-heart:before {
  content: '\e923';
}
._x_icon-mail:before {
  content: '\e924';
}
._x_icon-maragi:before {
  content: '\e925';
}
._x_icon-mastercard:before {
  content: '\e926';
}
._x_icon-messenger:before {
  content: '\e927';
}
._x_icon-minus:before {
  content: '\e928';
}
._x_icon-more-dots:before {
  content: '\e929';
}
._x_icon-orders:before {
  content: '\e92a';
}
._x_icon-plus:before {
  content: '\e92b';
}
._x_icon-print:before {
  content: '\e92c';
}
._x_icon-remove-bin:before {
  content: '\e92d';
}
._x_icon-remove:before {
  content: '\e92e';
}
._x_icon-search:before {
  content: '\e92f';
}
._x_icon-security:before {
  content: '\e930';
}
._x_icon-shop-store:before {
  content: '\e932';
}
._x_icon-sort:before {
  content: '\e933';
}
._x_icon-swap-compare:before {
  content: '\e934';
}
._x_icon-user-profile-1:before {
  content: '\e935';
}
._x_icon-user-profile:before {
  content: '\e936';
}
._x_icon-users-iuridiuli:before {
  content: '\e937';
}
._x_icon-visa:before {
  content: '\e938';
}
._x_icon-yt:before {
  content: '\e939';
}
._x_icon-Natsil-natsil:before {
  content: '\e913';
}
._x_icon-Promo_Code:before {
  content: '\e944';
}
._x_icon-return-1:before {
  content: '\e945';
}
._x_icon-file:before {
  content: '\e946';
}
._x_icon-cart-bucket-filled:before {
  content: "\e94f";
}
