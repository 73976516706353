@use 'sass:math';

@mixin ng-deep($enableNgDeep: true) {
  @if $enableNgDeep {
    ::ng-deep {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin rounded($radius: 2px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

$enableNgDeep: true !default;

$handleActiveColor: white;
$handleHoverColor: white;
$handleBgColor: #7a1dff;
$barFillColor: $handleBgColor;
$barNormalColor: #b9b9b9;
$handleInnerColor: #fff;
$barLeftOutSelectionColor: #df002d !default;
$barRightOutSelectionColor: #03a688 !default;

$ticksColor: $barNormalColor !default;
$selectedTicksColor: $barFillColor !default;
$ticksWidth: 10px !default;
$ticksHeight: 10px !default;
$ticksValuePosition: -34px !default;
$ticksLegendPosition: 24px !default;
$ticksValuePositionOnVertical: 24px !default;
$tickLegendMaxWidth: 50px !default;

$handleSize: 18px !default;
$handlePointerSize: 14px !default;
$bubblePaddingVertical: 1px !default;
$bubblePaddingHorizontal: 3px !default;
$labelFontSize: 16px !default;
$barDimension: 2px !default;

$withLegendMargin: 40px !default;

$sliderMargin: 10px !default;
$sliderMarginWithLabel: 20px !default;
$sliderVerticalMargin: 20px !default;

$animationDuration: 0.3s !default;

.custom--ng5-slider {
  .ngx-slider {
    display: inline-block;
    position: relative;
    height: $barDimension;
    width: 100%;
    margin: $sliderMarginWithLabel 0 $sliderMargin 0;
    vertical-align: middle;
    user-select: none;
    touch-action: pan-y;
    z-index: 0;
    .ngx-slider-bubble {
      display: none !important;
    }

    &.with-legend {
      margin-bottom: $withLegendMargin;
    }

    &[disabled] {
      cursor: not-allowed;

      .ngx-slider-draggable {
        cursor: not-allowed;
      }
    }

    .ngx-slider-span {
      white-space: nowrap;
      position: absolute;
      display: inline-block;
    }

    .ngx-slider-base {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .ngx-slider-bar-wrapper {
      left: 0;
      box-sizing: border-box;
      margin-top: math.div(-$handleSize, 2);
      padding-top: math.div($handleSize, 2);
      width: 100%;
      height: $handleSize;
      z-index: 1;
    }

    .ngx-slider-draggable {
      cursor: move;
    }

    .ngx-slider-bar {
      left: 0;
      width: 100%;
      height: $barDimension;
      z-index: 1;
      background: $barNormalColor;
      @include rounded(math.div($barDimension, 2));
    }

    .ngx-slider-bar-wrapper.ngx-slider-transparent .ngx-slider-bar {
      background: transparent;
    }

    .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar {
      background: $barLeftOutSelectionColor;
    }

    .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar {
      background: $barRightOutSelectionColor;
    }

    .ngx-slider-selection {
      z-index: 2;
      background: $barFillColor;
      @include rounded(math.div($barDimension, 2));
    }

    .ngx-slider-pointer {
      outline: none;
      cursor: pointer;
      width: $handleSize;
      height: $handleSize;
      top: math.div(-$handleSize, 2) + math.div($barDimension, 2);
      background-color: $handleBgColor;
      z-index: 3;
      @include rounded(math.div($handleSize, 2));

      &:hover:after {
        background-color: $handleHoverColor;
      }

      &.ngx-slider-active {
        z-index: 4;

        &:after {
          background-color: $handleActiveColor;
        }
      }
    }
    .ngx-slider-pointer {
      cursor: pointer;
      width: $handleSize;
      height: $handleSize;
      top: math.div(-$handleSize, 2) + math.div($barDimension, 2);
      background-color: $handleBgColor;
      z-index: 3;
      @include rounded(math.div($handleSize, 2));

      &:after {
        content: '';
        width: $handlePointerSize;
        height: $handlePointerSize;
        position: absolute;
        top: math.div($handleSize, 2) - math.div($handlePointerSize, 2);
        left: math.div($handleSize, 2) - math.div($handlePointerSize, 2);
        @include rounded(math.div($handlePointerSize, 2));
        background: $handleInnerColor;
      }

      &:hover:after {
        background-color: $handleHoverColor;
      }

      &.ngx-slider-active {
        z-index: 4;

        &:after {
          background-color: $handleActiveColor;
        }
      }
    }

    .drop {
      display: none;
    }

    .drop.active {
      display: block;
    }

    .ngx-slider-ticks {
      box-sizing: border-box;
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      top: math.div(-($ticksHeight - $barDimension), 2);
      margin: 0;
      z-index: 1;
      list-style: none;
    }

    .ngx-slider-ticks-values-under {
      .ngx-slider-tick-value {
        top: auto;
        bottom: $ticksValuePosition - 2;
      }
    }

    .ngx-slider-tick {
      text-align: center;
      cursor: pointer;
      width: $ticksWidth;
      height: $ticksHeight;
      background: $ticksColor;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: math.div($handleSize, 2) - math.div($ticksWidth, 2); // for centering

      &.ngx-slider-selected {
        background: $selectedTicksColor;
      }
    }

    .ngx-slider-tick-value {
      position: absolute;
      top: $ticksValuePosition;
      transform: translate(-50%, 0);
    }

    .ngx-slider-tick-legend {
      position: absolute;
      top: $ticksLegendPosition;
      transform: translate(-50%, 0);
      max-width: $tickLegendMaxWidth;
      white-space: normal;
    }

    &.vertical {
      position: relative;
      width: $barDimension;
      height: 100%;
      margin: 0 $sliderVerticalMargin;
      padding: 0;
      vertical-align: baseline;
      touch-action: pan-x;

      .ngx-slider-base {
        width: 100%;
        height: 100%;
        padding: 0;
      }

      .ngx-slider-bar-wrapper {
        top: auto;
        left: 0;
        margin: 0 0 0 (math.div(-$handleSize, 2));
        padding: 0 0 0 math.div($handleSize, 2);
        height: 100%;
        width: $handleSize;
      }

      .ngx-slider-bar {
        bottom: 0;
        left: auto;
        width: $barDimension;
        height: 100%;
      }

      .ngx-slider-pointer {
        left: math.div(-$handleSize, 2) + math.div($barDimension, 2) !important;
        top: auto;
        bottom: 0;
      }

      .ngx-slider-ticks {
        height: 100%;
        width: 0;
        left: math.div(-($ticksHeight - $barDimension), 2);
        top: 0;
        z-index: 1;
      }

      .ngx-slider-tick {
        vertical-align: middle;
        margin-left: auto;
        margin-top: math.div($handleSize, 2) - math.div($ticksWidth, 2); // for centering
      }

      .ngx-slider-tick-value {
        left: $ticksValuePositionOnVertical;
        top: auto;
        transform: translate(0, -28%);
      }

      .ngx-slider-tick-legend {
        top: auto;
        right: $ticksLegendPosition;
        transform: translate(0, -28%);
        max-width: none;
        white-space: nowrap;
      }

      .ngx-slider-ticks-values-under {
        .ngx-slider-tick-value {
          bottom: auto;
          left: auto;
          right: $ticksValuePositionOnVertical;
        }
      }
    }

    * {
      transition: none;
    }

    &.animate {
      .ngx-slider-bar-wrapper {
        //transition: all linear $animationDuration;
        transition: none;
      }

      .ngx-slider-selection {
        //transition: background-color linear $animationDuration;
        transition: none;
      }

      .ngx-slider-pointer {
        //transition: all linear $animationDuration;
        transition: none;
      }

      .ngx-slider-tick {
        //transition: background-color linear $animationDuration;
        transition: none;
      }
    }
  }
}

.ngx-slider-inner-tooltip {
  height: 100%;
}
