@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}
body {
  margin: 0;
  padding: 0;

  * {
    box-sizing: border-box;
  }
}

a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

p {
  margin: 0;
}

[hidden] {
  display: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
}

select {
  &._x_input {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    box-shadow: none;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  }

  &:hover,
  &:focus {
    outline: none;
  }
}
